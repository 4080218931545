@import url(http://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,700,300);
.no-max-width {
  max-width: 100%; }

.wall {
  width: 100%;
  max-width: 73.75rem;
  margin: 0 auto;
  padding: 3.125rem 2.5rem;
  z-index: 3; }
  .wall:after {
    content: '';
    clear: both;
    display: block;
    width: 100%; }

.wall--item {
  width: 33.3333%;
  height: auto;
  float: left;
  position: relative;
  padding: 0.625rem; }

.wall--item--inner {
  cursor: pointer;
  background-color: #eee;
  position: relative; }
  .wall--item--inner img {
    width: 100%;
    height: auto;
    display: block; }
  .wall--item--inner p {
    padding: 10px 0;
    font-size: 24px;
    background-color: #ffffff;
    text-align: center; }

.wall .wall--item--inner--expander {
  width: 100%;
  background-color: #eee;
  max-height: 0;
  transition: max-height 0.5s, margin-top 0.5s;
  font-size: 1rem;
  overflow: hidden;
  margin-top: 0;
  position: relative; }

.wall--item--inner--expander.wall--item--inner--expander--active {
  transition: max-height 0.5s, margin-top 0.5s;
  max-height: 87.5rem;
  margin-top: 0.625rem; }

.wall--item--inner--expander--close {
  background-image: url("/img/frontend/close.svg");
  height: 2.5rem;
  width: 2.5rem;
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  opacity: 0.75;
  transition: 0.3s; }
  .wall--item--inner--expander--close:hover {
    background-size: 90%;
    opacity: 1;
    transition: 0.3s; }

.wall--item--inner--expander--inner {
  max-width: 73.75rem;
  margin: 0 auto;
  padding: 4.6875rem 3.125rem;
  font-size: 0; }

.wall--item--inner--expander--inner--image {
  height: auto;
  width: 33.33333%;
  display: inline-block;
  vertical-align: top;
  padding-right: 0.3125rem; }
  .wall--item--inner--expander--inner--image img {
    max-width: 100%; }

.wall--item--inner--expander--inner--text {
  height: auto;
  width: 66.66666%;
  display: inline-block;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  font-size: 1.125rem;
  color: white;
  font-weight: 300; }

.wall--item--inner--expander--inner--text--headline {
  font-size: 1.875rem;
  font-weight: 300;
  padding-bottom: 1.5625rem; }

.sep {
  display: block;
  height: 0;
  width: 100%;
  float: left; }

.ro-1 > .wall--item--inner--expander {
  background-color: #93278f; }

.ro-2 > .wall--item--inner--expander {
  background-color: #c32627; }

.ro-3 > .wall--item--inner--expander {
  background-color: #2371a1; }

.ro-4 > .wall--item--inner--expander {
  background-color: #8da234; }

.ro-1 > .wall--item--inner:after,
.ro-2 > .wall--item--inner:after,
.ro-3 > .wall--item--inner:after,
.ro-4 > .wall--item--inner:after {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 0px solid;
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -1.25rem;
  transition: border-bottom 0.5s; }

.wall--item--inner.wall--item--inner--active:after {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid;
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -1.25rem;
  transition: border-bottom 0.5s;
  transition-delay: 0.5s; }

.ro-1 > .wall--item--inner:after {
  border-bottom-color: #93278f; }

.ro-2 > .wall--item--inner:after {
  border-bottom-color: #c32627; }

.ro-3 > .wall--item--inner:after {
  border-bottom-color: #2371a1; }

.ro-4 > .wall--item--inner:after {
  border-bottom-color: #8da234; }

.expander-button {
  background-color: rgba(10, 10, 10, 0.25);
  height: 2.8125rem;
  display: inline-block;
  margin-top: 1.875rem;
  transition: 0.1s;
  text-align: center; }
  .expander-button .expander-button--inner {
    padding: 0 1.25rem 0 2.8125rem;
    display: inline-block;
    font-weight: 300;
    text-decoration: none;
    line-height: 2.8125rem;
    height: 100%;
    color: white;
    background-image: url("/img/frontend/chain.svg");
    background-repeat: no-repeat;
    background-position: center left 0.9375rem;
    background-size: 1.25rem auto; }
  .expander-button:hover {
    background-color: rgba(10, 10, 10, 0.5);
    transition: 0.1s; }

@media screen and (max-width: 45.625rem) {
  .wall--item--inner--expander--inner--image {
    width: 100%;
    display: block;
    padding-bottom: 1.875rem; }
    .wall--item--inner--expander--inner--image img {
      display: none; }
  .wall--item--inner--expander {
    width: auto !important;
    margin-left: 0 !important; }
  .wall--item--inner--expander--inner--text {
    width: 100%;
    display: block; }
  .wall--item {
    width: 100%;
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .wall,
  .wall--item--inner--expander--inner {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .wall--item--inner--expander--inner--image {
    padding-bottom: 0; }
  .expander-button {
    display: block; } }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; }

form label {
  display: block;
  margin-top: 1.875rem; }
  form label:first-of-type {
    margin-top: 0.3125rem; }

form input[type="submit"] {
  height: 2.5rem;
  margin-top: 1.875rem; }

.imprint > :first-child {
  margin-top: 0; }

.imprint p {
  padding-left: 0.625rem;
  line-height: 1.40625rem; }

.imprint h2 {
  padding-bottom: 1.25rem;
  margin-top: 4.375rem; }

.imprint h3 {
  padding-bottom: 0.625rem;
  padding-top: 2.5rem;
  padding-left: 0.3125rem; }

.imprint h2 + h3 {
  padding-top: 0; }

.imprint hr {
  margin: 1.25rem 0;
  border: none;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.25); }

.imprint a {
  color: black; }

.contact {
  padding-top: 1.875rem; }
  .contact .column-1-2:first-of-type {
    padding-right: 3.125rem;
    text-align: right;
    font-size: 1.375rem;
    line-height: 2rem;
    font-weight: 300; }

body,
html {
  height: 100%;
  width: 100%; }

header {
  height: 3.5rem;
  width: 100%;
  background-color: #f2f2f2;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000; }

.header--inner {
  max-width: 73.75rem;
  padding: 0 2.5rem;
  margin: 0 auto;
  height: 100%; }

nav {
  height: 100%;
  display: block;
  float: right;
  padding-left: 0.625rem; }
  nav a {
    color: transparent;
    display: block;
    height: 100%;
    float: left;
    max-height: 2.125rem;
    margin-top: 0.625rem;
    margin-left: 0.625rem;
    background-color: white;
    border: 0.0625rem solid #aaa; }
    nav a:nth-of-type(1) {
      width: 7.5rem; }
    nav a:nth-of-type(2) {
      width: 10rem; }
    nav a:nth-of-type(3) {
      width: 8.4375rem; }
    nav a:hover {
      background-color: #f5f5f5; }
    nav a svg {
      height: 100%;
      width: auto;
      display: block;
      fill: transparent;
      margin: 0 auto; }
      nav a svg .svg-text {
        fill: black; }
    nav a.nav--a--active {
      background-color: black;
      border: 0.0625rem solid black; }
      nav a.nav--a--active svg {
        fill: transparent; }
      nav a.nav--a--active .svg-text {
        fill: white; }

.keyvisual {
  background-color: #f2f2f2;
  display: block;
  width: 100%;
  padding-top: 4.375rem;
  height: 28.125rem;
  max-height: 28.125rem;
  overflow: hidden; }

.keyvisual--inner {
  max-width: 73.75rem;
  padding: 0 2.5rem 1.25rem 2.5rem;
  margin: 0 auto;
  position: relative;
  height: 100%; }

.keyvisual--inner--vector {
  background-image: url("/img/frontend/ruhr-online_vectorart.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  height: 25.625rem;
  right: -15%;
  bottom: -3.75rem;
  left: 45%;
  position: absolute;
  z-index: 1; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .keyvisual--inner--vector {
    background-image: url("/img/frontend/ruhr-online_vectorart@retina.png"); } }

.keyvisual--inner--title,
.keyvisual--inner--text {
  max-width: 35.625rem;
  position: relative;
  z-index: 2; }

.keyvisual--inner--title {
  font-size: 2.5rem;
  line-height: 2.8125rem;
  padding-top: 3.125rem;
  font-weight: 300; }

.keyvisual--inner--text {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
  padding-right: 4.375rem;
  font-weight: 300; }

.header--inner--logo {
  height: 100%;
  width: 25.625rem;
  background-image: url("/img/frontend/ruhr-online_logo.svg");
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  float: left; }
  .header--inner--logo a {
    height: 100%;
    width: 100%;
    display: block; }

.content {
  max-width: 73.75rem;
  margin: 0 auto;
  padding: 3.125rem 1.875rem 6.25rem 1.875rem; }

.content--inner {
  height: auto;
  width: 100%; }
  .content--inner:after {
    content: '';
    clear: both;
    width: 100%;
    display: block; }

.content--inner--item {
  padding: 0.625rem; }
  .content--inner--item:nth-of-type(4n+1) > .content--inner--item--inner {
    background-color: #93278f; }
  .content--inner--item:nth-of-type(4n+2) > .content--inner--item--inner {
    background-color: #c32627; }
  .content--inner--item:nth-of-type(4n+3) > .content--inner--item--inner {
    background-color: #2371a1; }
  .content--inner--item:nth-of-type(4n+4) > .content--inner--item--inner {
    background-color: #8da234; }
  .content--inner--item.content-item-biggie {
    width: 100%; }

.content--inner--item--description--picture {
  padding-top: 100%;
  position: relative;
  margin-bottom: 0.625rem; }
  .content--inner--item--description--picture img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    right: 0; }

.content--inner--item--description--location,
.content--inner--item--description--date,
.content--inner--item--description--title,
.content--inner--item--description--text {
  color: white; }

.content--inner--item--description--location,
.content--inner--item--description--date {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 300; }

.content--inner--item--description--location {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem; }

.content--inner--item--description--date {
  padding: 0 1.25rem 0 1.25rem; }
  .content--inner--item--description--date:after {
    content: '';
    display: block;
    width: 100%;
    padding-top: 1.25rem;
    border-bottom: 0.0625rem dotted white; }

.content--inner--item--description--title {
  font-size: 1.5625rem;
  font-weight: 300;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem; }

.content--inner--item--description--text {
  font-size: 1rem;
  font-weight: 300;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  line-height: 1.40625rem; }

.content--inner--item--description--button {
  padding: 0 1.25rem 1.25rem 1.25rem; }

.content--inner--item--description--button--inner {
  background-color: rgba(10, 10, 10, 0.25);
  height: 100%;
  width: 100%;
  display: block;
  height: 2.8125rem;
  line-height: 2.8125rem;
  padding: 0 0.625rem;
  text-decoration: none;
  color: white;
  font-size: 1.25rem;
  font-weight: 300;
  transition: 0.1s;
  text-align: center; }
  .content--inner--item--description--button--inner:hover {
    background-color: rgba(10, 10, 10, 0.5);
    transition: 0.1s; }

.content--inner--item--description--button--inner--content {
  display: inline-block;
  padding: 0 0.625rem 0 2.5rem;
  background-image: url("/img/frontend/chain.svg");
  background-position: center left 0.625rem;
  background-repeat: no-repeat;
  background-size: 1.25rem auto; }

.content-item-biggie {
  position: relative; }
  .content-item-biggie:after {
    content: '';
    clear: both;
    display: block; }
  .content-item-biggie .content--inner--item--biggie-picture {
    max-width: 66.65%;
    width: 100%;
    left: 0.625rem;
    top: 0.625rem;
    bottom: 0.625rem;
    position: absolute;
    overflow-x: hidden; }
    .content-item-biggie .content--inner--item--biggie-picture img {
      display: block;
      height: 100%;
      float: right; }
  .content-item-biggie .content--inner--item--description {
    padding-left: 68%; }

.content--inner--item,
.content--inner--masonryColumnWidth {
  width: 33.33333%; }

footer {
  width: 100%;
  background-color: #E0E0E0;
  display: block; }

.footer--inner {
  max-width: 73.75rem;
  padding: 0 2.5rem;
  position: relative;
  margin: 0 auto; }
  .footer--inner ul {
    list-style: none;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 300;
    float: left;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
    .footer--inner ul li {
      display: inline; }
    .footer--inner ul li:nth-of-type(1),
    .footer--inner ul li:nth-of-type(2) {
      display: inline;
      border-right: 0.075em solid black;
      padding-right: 0.75em;
      margin-right: 0.75em; }
    .footer--inner ul li > a {
      text-decoration: none;
      color: black; }
    .footer--inner ul li b {
      text-decoration: none;
      color: black;
      font-weight: 500;
      margin-left: 0.3125rem; }
  .footer--inner p {
    font-size: 1.5625rem;
    border-bottom: 0.05em dotted black;
    text-align: center;
    font-weight: 300;
    padding: 1.875rem 3.125rem 1.125rem 3.125rem;
    max-width: 39.0625rem;
    margin: 1.5625rem auto; }

.footer--inner--footerimage_left {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 14.375rem;
  width: 16.5vw; }

.footer--inner--footerimage_right {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 15rem;
  width: 16.5vw; }

.footer--inner--share {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  padding-bottom: 3.125rem;
  line-height: 2.5rem; }

.footer--inner--share a {
  color: black;
  text-decoration: none; }

.headline {
  width: 100%;
  margin-top: 6.875rem; }

.headline--title,
.headline--subtitle {
  font-weight: 300;
  text-align: center; }

.headline--title {
  font-size: 3.125rem;
  padding-bottom: 0.9375rem; }

.headline--subtitle {
  font-size: 1.125rem; }

small.copyright {
  position: absolute;
  bottom: 5px;
  right: 0;
  padding: 4px;
  opacity: .8;
  background: #000;
  color: #fff; }

@media screen and (max-width: 62.5rem) {
  .header--inner--logo {
    width: 9.375rem; }
  .content--inner--item,
  .content--inner--masonryColumnWidth {
    width: 50%; }
  .content-item-biggie .content--inner--item--biggie-picture {
    max-width: 50%; }
  .content-item-biggie .content--inner--item--description {
    padding-left: 51.2%; }
  .keyvisual--inner--title,
  .keyvisual--inner--text,
  .keyvisual--inner--vector {
    display: none; }
  .keyvisual {
    height: auto;
    max-height: auto;
    padding: 3.5rem 0 0 0; }
  .keyvisual--inner {
    padding: 39.9% 0 0 0;
    background-image: url("/img/frontend/ruhr-online_vectorart_1136.jpg");
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat; } }

@media screen and (max-width: 45.625rem) {
  .contact .column-1-2 {
    width: 100%;
    padding: 1.25rem; }
    .contact .column-1-2:first-of-type {
      text-align: left; }
  .header--inner {
    padding: 0; }
  .header--inner--logo {
    display: none; }
  nav {
    height: 100%;
    width: 100%;
    display: block;
    float: none;
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
    nav a {
      margin-left: 2%; }
      nav a:nth-of-type(1) {
        margin-left: 0;
        width: 29%; }
      nav a:nth-of-type(2) {
        width: 36%; }
      nav a:nth-of-type(3) {
        width: 31%; }
      nav a svg {
        width: auto;
        height: 80%;
        margin: 0.25rem auto 0 auto; }
  .content--inner--item,
  .content--inner--masonryColumnWidth {
    width: 100%; }
  .content--inner--item {
    padding: 0 0 0.9375rem 0; }
  .content {
    padding: 0.9375rem 0 0rem 0; }
  .footer--inner--footerimage_left,
  .footer--inner--footerimage_right {
    display: none; }
  .content-item-biggie .content--inner--item--biggie-picture {
    max-width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 42%;
    overflow-y: hidden; }
    .content-item-biggie .content--inner--item--biggie-picture img {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      display: block; }
  .content-item-biggie .content--inner--item--description {
    padding-left: 0; }
  .content--inner--item--description--location,
  .content--inner--item--description--date,
  .content--inner--item--description--title,
  .content--inner--item--description--text,
  .content--inner--item--description--button {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .keyvisual--inner {
    padding: 39.9% 0 0 0;
    background-image: url("/img/frontend/ruhr-online_vectorart_568.jpg");
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat; }
  .wall,
  .wall--item--inner--expander--inner--text {
    padding-left: 0;
    padding-right: 0; }
  .wall--item--inner--expander--inner {
    padding-top: 1.25rem;
    padding-bottom: 0.625rem; } }

body.stop-scrolling {
  height: 100%;
  overflow: hidden; }

.sweet-overlay {
  background-color: black;
  /* IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  /* IE8 */
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 10000; }

.sweet-alert {
  background-color: white;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 478px;
  padding: 17px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -256px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 99999; }

@media all and (max-width: 540px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 15px;
    right: 15px; } }

.sweet-alert h2 {
  color: #575757;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 25px 0;
  padding: 0;
  line-height: 40px;
  display: block; }

.sweet-alert p {
  color: #797979;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  position: relative;
  text-align: inherit;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal; }

.sweet-alert fieldset {
  border: none;
  position: relative; }

.sweet-alert .sa-error-container {
  background-color: #f1f1f1;
  margin-left: -17px;
  margin-right: -17px;
  overflow: hidden;
  padding: 0 10px;
  max-height: 0;
  webkit-transition: padding 0.15s, max-height 0.15s;
  transition: padding 0.15s, max-height 0.15s; }

.sweet-alert .sa-error-container.show {
  padding: 10px 0;
  max-height: 100px;
  webkit-transition: padding 0.2s, max-height 0.2s;
  transition: padding 0.25s, max-height 0.25s; }

.sweet-alert .sa-error-container .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ea7d7d;
  color: white;
  line-height: 24px;
  text-align: center;
  margin-right: 3px; }

.sweet-alert .sa-error-container p {
  display: inline-block; }

.sweet-alert .sa-input-error {
  position: absolute;
  top: 29px;
  right: 26px;
  width: 20px;
  height: 20px;
  opacity: 0;
  transform: scale(0.5);
  transform-origin: 50% 50%;
  transition: all 0.1s; }

.sweet-alert .sa-input-error::before, .sweet-alert .sa-input-error::after {
  content: "";
  width: 20px;
  height: 6px;
  background-color: #f06e57;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  left: 50%;
  margin-left: -9px; }

.sweet-alert .sa-input-error::before {
  transform: rotate(-45deg); }

.sweet-alert .sa-input-error::after {
  transform: rotate(45deg); }

.sweet-alert .sa-input-error.show {
  opacity: 1;
  transform: scale(1); }

.sweet-alert input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #d7d7d7;
  height: 43px;
  margin-top: 10px;
  margin-bottom: 17px;
  font-size: 18px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 0 12px;
  display: none;
  transition: all 0.3s; }

.sweet-alert input:focus {
  outline: none;
  box-shadow: 0px 0px 3px #c4e6f5;
  border: 1px solid #b4dbed; }

.sweet-alert input:focus::-moz-placeholder {
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.5; }

.sweet-alert input:focus:-ms-input-placeholder {
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.5; }

.sweet-alert input:focus::-webkit-input-placeholder {
  transition: opacity 0.3s 0.03s ease;
  opacity: 0.5; }

.sweet-alert input::-moz-placeholder {
  color: #bdbdbd; }

.sweet-alert input:-ms-input-placeholder {
  color: #bdbdbd; }

.sweet-alert input::-webkit-input-placeholder {
  color: #bdbdbd; }

.sweet-alert.show-input input {
  display: block; }

.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative; }

.sweet-alert .la-ball-fall {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -27px;
  margin-top: 4px;
  opacity: 0;
  visibility: hidden; }

.sweet-alert button {
  background-color: #8CD4F5;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer; }

.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05); }

.sweet-alert button:hover {
  background-color: #7ecff4; }

.sweet-alert button:active {
  background-color: #5dc2f1; }

.sweet-alert button.cancel {
  background-color: #C1C1C1; }

.sweet-alert button.cancel:hover {
  background-color: #b9b9b9; }

.sweet-alert button.cancel:active {
  background-color: #a8a8a8; }

.sweet-alert button.cancel:focus {
  box-shadow: rgba(197, 205, 211, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset !important; }

.sweet-alert button[disabled] {
  opacity: .6;
  cursor: default; }

.sweet-alert button.confirm[disabled] {
  color: transparent; }

.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s; }

.sweet-alert button::-moz-focus-inner {
  border: 0; }

.sweet-alert[data-has-cancel-button=false] button {
  box-shadow: none !important; }

.sweet-alert[data-has-confirm-button=false][data-has-cancel-button=false] {
  padding-bottom: 40px; }

.sweet-alert .sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  border-radius: 40px;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box; }

.sweet-alert .sa-icon.sa-error {
  border-color: #F27474; }

.sweet-alert .sa-icon.sa-error .sa-x-mark {
  position: relative;
  display: block; }

.sweet-alert .sa-icon.sa-error .sa-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F27474;
  display: block;
  top: 37px;
  border-radius: 2px; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  transform: rotate(45deg);
  left: 17px; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  transform: rotate(-45deg);
  right: 16px; }

.sweet-alert .sa-icon.sa-warning {
  border-color: #F8BB86; }

.sweet-alert .sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #F8BB86; }

.sweet-alert .sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #F8BB86; }

.sweet-alert .sa-icon.sa-info {
  border-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-info::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-info::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #C9DAE1; }

.sweet-alert .sa-icon.sa-success {
  border-color: #A5DC86; }

.sweet-alert .sa-icon.sa-success::before, .sweet-alert .sa-icon.sa-success::after {
  content: '';
  border-radius: 40px;
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: white;
  transform: rotate(45deg); }

.sweet-alert .sa-icon.sa-success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  transform: rotate(-45deg);
  transform-origin: 60px 60px; }

.sweet-alert .sa-icon.sa-success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  transform: rotate(-45deg);
  transform-origin: 0px 60px; }

.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  border-radius: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2; }

.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: white;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  transform: rotate(-45deg); }

.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #A5DC86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg); }

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  transform: rotate(-45deg); }

.sweet-alert .sa-icon.sa-custom {
  background-size: contain;
  border-radius: 0;
  border: none;
  background-position: center center;
  background-repeat: no-repeat; }

/*
 * Animations
 */

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7); }
  45% {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }
  80% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95); }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5); } }

@keyframes slideFromTop {
  0% {
    top: 0%; }
  100% {
    top: 50%; } }

@keyframes slideToTop {
  0% {
    top: 50%; }
  100% {
    top: 0%; } }

@keyframes slideFromBottom {
  0% {
    top: 70%; }
  100% {
    top: 50%; } }

@keyframes slideToBottom {
  0% {
    top: 50%; }
  100% {
    top: 70%; } }

.showSweetAlert[data-animation=pop] {
  animation: showSweetAlert 0.3s; }

.showSweetAlert[data-animation=none] {
  animation: none; }

.showSweetAlert[data-animation=slide-from-top] {
  animation: slideFromTop 0.3s; }

.showSweetAlert[data-animation=slide-from-bottom] {
  animation: slideFromBottom 0.3s; }

.hideSweetAlert[data-animation=pop] {
  animation: hideSweetAlert 0.2s; }

.hideSweetAlert[data-animation=none] {
  animation: none; }

.hideSweetAlert[data-animation=slide-from-top] {
  animation: slideToTop 0.4s; }

.hideSweetAlert[data-animation=slide-from-bottom] {
  animation: slideToBottom 0.3s; }

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

.animateSuccessTip {
  animation: animateSuccessTip 0.75s; }

.animateSuccessLong {
  animation: animateSuccessLong 0.75s; }

.sa-icon.sa-success.animate::after {
  animation: rotatePlaceholder 4.25s ease-in; }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1; } }

.animateErrorIcon {
  animation: animateErrorIcon 0.5s; }

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px; }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

.animateXMark {
  animation: animateXMark 0.5s; }

@keyframes pulseWarning {
  0% {
    border-color: #F8D486; }
  100% {
    border-color: #F8BB86; } }

.pulseWarning {
  animation: pulseWarning 0.75s infinite alternate; }

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }

.pulseWarningIns {
  animation: pulseWarningIns 0.75s infinite alternate; }

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Internet Explorer 9 has some special quirks that are fixed here */
/* The icons are not animated. */
/* This file is automatically merged into sweet-alert.min.js through Gulp */
/* Error icon */
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg) \9; }

/* Success icon */
.sweet-alert .sa-icon.sa-success {
  border-color: transparent\9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9; }

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9; }

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-fall,
.la-ball-fall > div {
  position: relative;
  box-sizing: border-box; }

.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff; }

.la-ball-fall.la-dark {
  color: #333; }

.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor; }

.la-ball-fall {
  width: 54px;
  height: 18px; }

.la-ball-fall > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  opacity: 0;
  animation: ball-fall 1s ease-in-out infinite; }

.la-ball-fall > div:nth-child(1) {
  animation-delay: -200ms; }

.la-ball-fall > div:nth-child(2) {
  animation-delay: -100ms; }

.la-ball-fall > div:nth-child(3) {
  animation-delay: 0ms; }

.la-ball-fall.la-sm {
  width: 26px;
  height: 8px; }

.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px; }

.la-ball-fall.la-2x {
  width: 108px;
  height: 36px; }

.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px; }

.la-ball-fall.la-3x {
  width: 162px;
  height: 54px; }

.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px; }

/*
 * Animation
 */

@keyframes ball-fall {
  0% {
    opacity: 0;
    transform: translateY(-145%); }
  10% {
    opacity: .5; }
  20% {
    opacity: 1;
    transform: translateY(0); }
  80% {
    opacity: 1;
    transform: translateY(0); }
  90% {
    opacity: .5; }
  100% {
    opacity: 0;
    transform: translateY(145%); } }

/*# sourceMappingURL=app.css.map */
